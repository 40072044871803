// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { Modal, Tabs } from "tailwindcss-stimulus-components";
import { TabsController } from "./stimulus-tabs/tabs_controller";

const application = Application.start();
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

application.register("modal", Modal);
application.register("tabs", Tabs);
application.register("my-tabs", TabsController);
