import React from "react";
import ReactDOM from "react-dom";
import { Controller } from "stimulus";
import { TrixContentAttachmentPreviewHelperService } from "@/src/TrixContentAttachmentPreviewHelperService";
import { AttachmentPreview } from "../react/components/shared/AttachmentPreview";

export default class extends Controller {
  connect() {
    this.contentPath = this.data.get("parentContentPath");
    this.trixContentAttachmentPreviewHelperService =
      new TrixContentAttachmentPreviewHelperService(this.element);
    this.showAttachmentPreview = this._showAttachmentPreview.bind(this);
    this.trixContentAttachmentPreviewHelperService.addAttachmentClickEventHandler(
      this.showAttachmentPreview,
    );
  }

  _showAttachmentPreview({ attachments, currentIndex }) {
    this.divElement = document.createElement("div");
    document.querySelector("body").append(this.divElement);
    ReactDOM.render(
      <AttachmentPreview
        attachments={attachments}
        currentIndex={currentIndex}
        onClose={this.hideAttachmentPreview}
        show={true}
        path={this.contentPath}
      />,
      this.divElement,
    );
  }

  hideAttachmentPreview = () => {
    this.divElement.remove();
  };
}
